import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const appWidth = 65
const apps = [
  {
    image: require("../images/communify.jpg"),
    name: "Communify",
    url: "https://apps.apple.com/gb/app/communify/id1366552547",
  },

  {
    image: require("../images/dyme.jpg"),
    name: "Dyme",
    url:
      "https://apps.apple.com/nl/app/dyme-track-je-geld-automatisch/id1438647102",
  },

  {
    image: require("../images/mypo.jpg"),
    name: "Mypo",
    url: "https://apps.apple.com/nl/app/mypo-order-tracking/id1296191492",
  },

  {
    image: require("../images/bencompare.jpg"),
    name: "Bencompare",
    url: "https://apps.apple.com/nl/app/bencompare/id1237689750?l=en",
  },
  {
    image: require("../images/dunbar.jpg"),
    name: "Dunbar",
    url: "https://apps.apple.com/ca/app/dunbar/id1457448974",
  },
  {
    image: require("../images/friendtime.png"),
    name: "FriendTime",
    url: "https://apps.apple.com/gb/app/friendtime-app/id1485717947",
  },
  {
    image: require("../images/ovfiets.jpg"),
    name: "OV-Fiets",
    url: "https://apps.apple.com/us/app/ov-fiets-app/id1471996949",
  },
]
class Home extends React.Component {
  renderItem({ item, index }) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={item.url}>
        <img
          src={item.image}
          alt={item.name}
          style={{
            margin: 8,
            width: appWidth,
            height: appWidth,
            borderRadius: 15,
          }}
        />
      </a>
    )
  }

  renderApps() {
    return (
      <div
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {apps.map((item, index) => this.renderItem({ item, index }))}
      </div>
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="Portfolio" />
        <h1>Werk</h1>

        {this.renderApps()}
      </Layout>
    )
  }
}

export default Home
